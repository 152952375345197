<template>
  <div class="page-not-found grey darken-3 fill-height">
    <v-layout fill-height justify-start align-start>
      <v-container class="mt-10 white--text">
        <v-btn :to="{name: 'Home'}" tag="a" text dark color="grey">
          <v-icon>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
        <div class="pl-5">
          <div class="mt-5" :class="$vuetify.breakpoint.lgOnly ? 'display-1' : ''">
            Página Não Encontrada.
          </div>
        </div>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
